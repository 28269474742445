import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { Menu, Grid } from 'antd';
import { useAtom } from 'jotai';

import Login from './Login';
import EditPrices from './EditPrices';
import EditProduct from './EditProduct';
import AddProduct from './AddProduct';
import EditBlog from './EditBlog';
import AddBlog from './AddBlog';
import { userAtom } from '../atoms';

import './Admin.css';

function Admin({ db, storage }) {
  const auth = getAuth();
  const analytics = getAnalytics();
  const screen = Grid.useBreakpoint();

  const [user, setUser] = useAtom(userAtom);
  const [page, setPage] = useState('product-prices');

  useEffect(() => {
    let unsubscribe;
    if (auth) unsubscribe = onAuthStateChanged(auth, (user) => setUser(user));
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [auth, setUser]);

  useEffect(() => {
    if (user?.isAnonymous) {
      signOut(auth);
    }
  }, [user, auth]);

  useEffect(() => {
    logEvent(analytics, 'screen_view', { firebase_screen: 'Admin', firebase_screen_class: 'admin' });
    logEvent(analytics, 'view_admin');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menuItems = [
    // { key: 'home', label: 'Homepage', children: [{ key: 'carousel-images', label: 'Carousel Images' }] },
    {
      key: 'products',
      label: 'Products',
      children: [
        { key: 'product-prices', label: 'Manage Prices' },
        // { key: 'product-categories', label: 'Edit Categories' },
        { key: 'product-edit', label: 'Edit Products' },
        { key: 'product-add', label: 'Add Product' },
        // { key: 'product-sale', label: 'Sale Items' },
      ],
    },
    {
      key: 'blog',
      label: 'Blog',
      children: [
        { key: 'blog-edit', label: 'Edit News' },
        { key: 'blog-add', label: 'Add News' },
      ],
    },
    // {
    //   key: 'footer',
    //   label: 'Footer',
    //   children: [
    //     { key: 'footer-links', label: 'Links' },
    //     { key: 'footer-contact', label: 'Contact Info' },
    //     { key: 'footer-social', label: 'Social Links' },
    //   ],
    // },
    {
      key: 'sign-out',
      label: 'Log out',
      onClick: () => {
        setPage();
        signOut(auth);
      },
    },
  ];

  function SideMenu() {
    return (
      <div className="side-menu">
        <Menu
          theme="dark"
          mode="inline"
          items={menuItems}
          inlineCollapsed={!screen.md}
          defaultOpenKeys={['products']}
          defaultSelectedKeys={['product-edit']}
          selectedKeys={[page]}
          onSelect={(s) => setPage(s.key)}
        />
      </div>
    );
  }

  function MainContent() {
    switch (page) {
      case 'product-prices':
        return <EditPrices db={db} storage={storage} />;
      case 'product-edit':
        return <EditProduct db={db} storage={storage} />;
      case 'product-add':
        return <AddProduct db={db} storage={storage} />;
      case 'blog-edit':
        return <EditBlog db={db} storage={storage} />;
      case 'blog-add':
        return <AddBlog db={db} storage={storage} />;
      default:
        return <div />;
    }
  }

  return (
    <div className="admin">
      <h1 className="page-title">Administration</h1>
      <div className="content">
        {user && !user.isAnonymous ? (
          <>
            {SideMenu()}
            <div className="main-content">{MainContent()}</div>
          </>
        ) : (
          <Login auth={auth} />
        )}
      </div>
    </div>
  );
}

export default Admin;
