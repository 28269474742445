import { useState, useEffect } from 'react';
import { Form, Input, Button, Progress, Switch, message } from 'antd';
import { collection, addDoc, updateDoc, doc } from 'firebase/firestore';
import { ref, uploadBytesResumable } from 'firebase/storage';
import Quill from 'react-quill';
import dayjs from 'dayjs';

import { urlFormat } from '../util';
import { STORAGE_URL } from '../constants';
import BlogDisplay from '../Blog/BlogDisplay';

import './AddBlog.css';

const ALLOW_DATE = true;

const generateUrl = (title, time) => (title ? `${time.format('YYYY-MM-DD')}-${urlFormat(title)}` : '');

function AddBlog({ db, storage, existingBlog, backCallback, successCallback }) {
  const [form] = Form.useForm();
  const [imageForm] = Form.useForm();
  const [pendingImage, setPendingImage] = useState(
    existingBlog?.image ? { path: existingBlog.image, progress: 100 } : undefined
  );
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [, setformValues] = useState();

  useEffect(() => {
    if (existingBlog) {
      const values = {
        ...existingBlog,
      };
      form.setFieldsValue(values);
      setformValues(values);
    }
  }, [form, existingBlog]);

  const uploadImage = (e) => {
    e.preventDefault();

    const folder = form.getFieldValue('title');
    if (!folder || !e.target?.files[0]) return;

    const file = e.target.files[0];
    const storageRef = ref(storage, `${urlFormat(folder)}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const path = uploadTask.snapshot.ref._location.path_;
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setPendingImage({ path, progress });
      },
      (error) => {
        alert(error);
        const path = uploadTask.snapshot.ref._location.path_;
        setPendingImage({ path, error });
      },
      () => {
        // finished
      }
    );
  };

  const values = form.getFieldsValue();
  const image = pendingImage && pendingImage.progress === 100 ? pendingImage.path : undefined;
  const time = values.overrideDate ? dayjs(values.overrideDate) : dayjs();
  const blog = {
    ...values,
    url: generateUrl(values.title, time),
    time: time.format(),
    image,
  };

  const resetForm = () => {
    form.resetFields();
    imageForm.resetFields();
    setPendingImage();
  };

  const onSubmit = async () => {
    form.validateFields();
    if (!(values && values.title && values.text)) {
      message.error('Fill out all required fields');
      return;
    }

    try {
      Object.keys(blog).forEach((key) => {
        if (blog[key] === undefined) delete blog[key];
      });

      if (existingBlog) {
        await updateDoc(doc(db, 'blog', existingBlog.id), blog);
        console.log('Document updated with ID: ', existingBlog.id, blog);
        successCallback();
        backCallback();
      } else {
        const docRef = await addDoc(collection(db, 'blog'), blog);
        console.log('Document written with ID: ', docRef.id, blog);
        resetForm();
      }

      message.success('Blog was saved successfully');
    } catch (err) {
      console.error('error saving blog', err, blog);
      message.error('There was an error saving the blog');
    }
  };

  function BlogForm() {
    return (
      <>
        <Form name="add-blog" autoComplete="off" form={form} onFieldsChange={(_, values) => setformValues(values)}>
          <Form.Item label="Title" name="title" rules={[{ required: true, message: 'Required' }]}>
            <Input />
          </Form.Item>
          {ALLOW_DATE && (
            <Form.Item label="Override Date" name="overrideDate">
              <Input />
            </Form.Item>
          )}
          <Form.Item label="Text" name="text" rules={[{ required: true, message: 'Required' }]}>
            <Quill
              theme="snow"
              modules={{
                toolbar: [
                  [{ font: [] }, { size: [] }],
                  ['bold', 'italic', 'underline', 'strike'],
                  ['direction', { align: [] }],
                  [{ color: [] }, { background: [] }],
                  [{ script: 'super' }, { script: 'sub' }],
                  [{ list: 'ordered' }, { list: 'bullet' }],
                  ['link', 'image', 'video'],
                  ['clean'],
                ],
              }}
            />
          </Form.Item>
        </Form>
        <div className="image-upload">
          <Form form={imageForm}>
            <Form.Item label="Upload image" name="image">
              <Input
                type="file"
                accept="image/*"
                onChange={uploadImage}
                disabled={pendingImage || !form.getFieldValue('title')}
              />
            </Form.Item>
            {pendingImage && (
              <div className="pending-images">
                <div className="pending-image">
                  {pendingImage.progress === 100 ? (
                    <img alt="" src={`${STORAGE_URL}${pendingImage.path}`} />
                  ) : (
                    <Progress type="circle" percent={pendingImage.progress} width={40} />
                  )}
                  <div className="path">{pendingImage.path}</div>
                  {pendingImage.progress === 100 && (
                    <Button className="delete-button" danger>
                      Delete
                    </Button>
                  )}
                </div>
              </div>
            )}
          </Form>
        </div>
        <Button className="submit-button" size="large" onClick={onSubmit}>
          Save Blog
        </Button>
      </>
    );
  }

  return (
    <div className="add-blog">
      <div className="top">
        {backCallback && <Button onClick={backCallback}>Back</Button>}
        <div className="preview">
          <Switch
            checkedChildren="Preview"
            unCheckedChildren="Preview"
            checked={isPreviewing}
            onChange={(checked) => {
              setIsPreviewing(checked);
              imageForm.resetFields();
            }}
          />
        </div>
      </div>
      {isPreviewing ? (
        <div className="previewing">
          <BlogDisplay blog={blog} />
        </div>
      ) : (
        BlogForm()
      )}
    </div>
  );
}

export default AddBlog;
