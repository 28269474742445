import { useState, useEffect, useCallback } from 'react';
import { Collapse, Avatar, List } from 'antd';
import { collection, getDocs, query } from 'firebase/firestore';
import { useAtom } from 'jotai';
import sortBy from 'lodash/sortBy';

import { productCategoriesAtom } from '../atoms';
import { STORAGE_URL } from '../constants';
import AddProduct from './AddProduct';

import './EditProduct.css';

function EditProduct({ db, storage }) {
  const [productCategories] = useAtom(productCategoriesAtom);
  const [products, setProducts] = useState();
  const [selectedProduct, setSelectedProduct] = useState();

  const fetchProducts = useCallback(() => {
    const ref = collection(db, 'products');
    const q = query(ref);
    getDocs(q).then((querySnapshot) => {
      const list = [];
      querySnapshot.forEach((doc) => list.push({ ...doc.data(), id: doc.id }));
      setProducts(sortBy(list, (p) => p.name));
    });
  }, [db, setProducts]);

  useEffect(() => {
    if (productCategories && !products) fetchProducts();
  }, [productCategories, products, fetchProducts]);

  function ProductSelector() {
    return (
      <Collapse bordered={false}>
        {productCategories?.map((c) => (
          <Collapse.Panel header={c.name} key={c.name}>
            <Collapse bordered={false}>
              {c.subCategories.map((s) => (
                <Collapse.Panel header={s} key={s}>
                  <List
                    itemLayout="horizontal"
                    dataSource={products?.filter((p) => p.subCategory === s) || []}
                    renderItem={(item) => (
                      <List.Item onClick={() => setSelectedProduct(item)}>
                        <List.Item.Meta
                          avatar={
                            <Avatar shape="square" size="large" src={`${STORAGE_URL}thumbs128/${item.images[0]}`} />
                          }
                          title={`${item.hidden ? 'HIDDEN: ' : ''}${item.onSale ? 'SALE: ' : ''}${item.brand} ${
                            item.name
                          }`}
                          description={item.price}
                        />
                      </List.Item>
                    )}
                  />
                </Collapse.Panel>
              ))}
            </Collapse>
          </Collapse.Panel>
        ))}
      </Collapse>
    );
  }

  return (
    <div className="edit-product">
      {selectedProduct && (
        <AddProduct
          db={db}
          storage={storage}
          existingProduct={selectedProduct}
          backCallback={() => setSelectedProduct()}
          successCallback={() => setProducts()}
        />
      )}
      <div className={selectedProduct ? 'invisible' : 'product-selector'}>{ProductSelector()}</div>
    </div>
  );
}

export default EditProduct;
