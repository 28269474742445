import { useState, useEffect, useCallback } from 'react';
import { collection, getDocs, query, orderBy, where } from 'firebase/firestore';
import { useSearchParams, createSearchParams, useNavigate } from 'react-router-dom';
import { List, Avatar, Pagination, Form, Input, Button } from 'antd';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { MdSearch } from 'react-icons/md';
import { useAtom } from 'jotai';
import dayjs from 'dayjs';

import { STORAGE_URL, LOGO_URL } from './constants';
import { searchDataAtom, productCategoriesAtom } from './atoms';
import { includesAll, urlFormat } from './util';

import './Search.css';

function Search({ db }) {
  const analytics = getAnalytics();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [productCategories] = useAtom(productCategoriesAtom);
  const [searchData, setSearchData] = useAtom(searchDataAtom);
  const [searchTerms, setSearchTerms] = useState();
  const [products, setProducts] = useState();
  const [blogs, setBlogs] = useState();
  const [matching, setMatching] = useState({ products: [], blogs: [] });
  const [page, setPage] = useState(1);

  const navigateTo = (url) => {
    navigate(url);
    window.scrollTo({ left: 0, top: 0 });
  };

  const fetchProducts = useCallback(() => {
    const ref = collection(db, 'products');
    const q = query(ref, orderBy('name'), where('hidden', '==', false));
    getDocs(q).then((querySnapshot) => {
      const list = [];
      querySnapshot.forEach((doc) => list.push({ ...doc.data(), id: doc.id }));
      setProducts(list);
    });
  }, [db, setProducts]);

  const fetchBlogs = useCallback(() => {
    const ref = collection(db, 'blog');
    const q = query(ref, orderBy('time', 'desc'));
    getDocs(q).then((querySnapshot) => {
      const list = [];
      querySnapshot.forEach((doc) => list.push({ ...doc.data(), id: doc.id }));
      setBlogs(list);
    });
  }, [db, setBlogs]);

  useEffect(() => {
    logEvent(analytics, 'screen_view', { firebase_screen: 'Search', firebase_screen_class: 'search' });
    logEvent(analytics, 'view_search');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!searchData && !products) fetchProducts();
  }, [searchData, products, fetchProducts]);

  useEffect(() => {
    if (!searchData && !blogs) fetchBlogs();
  }, [searchData, blogs, fetchBlogs]);

  useEffect(() => {
    if (!searchData && products && blogs) {
      setSearchData({ products, blogs });
    }
  }, [searchData, products, blogs, setSearchData]);

  useEffect(() => {
    setSearchTerms(searchParams?.get('s')?.toLowerCase().split(' '));
    form.setFieldValue('searchInput', searchParams?.get('s'));
  }, [searchParams, form]);

  useEffect(() => {
    if (searchData && searchTerms?.length) {
      const matchingProducts = searchData.products
        .filter(
          (p) =>
            includesAll(p.name, searchTerms) ||
            includesAll(p.brand, searchTerms) ||
            includesAll(p.category, searchTerms) ||
            includesAll(p.subCategory, searchTerms) ||
            includesAll(p.description, searchTerms)
        )
        .map((p) => ({ ...p, type: 'product' }));
      const matchingBlogs = searchData.blogs
        .filter((p) => includesAll(p.title, searchTerms) || includesAll(p.text, searchTerms))
        .map((b) => ({ ...b, type: 'blog' }));
      setMatching({ products: matchingProducts, blogs: matchingBlogs });
    }
  }, [searchData, searchTerms]);

  const matchingItems = matching ? [...matching.products, ...matching.blogs] : [];

  function SearchForm() {
    return (
      <div className="search-input">
        <Form
          form={form}
          onFinish={({ searchInput }) => {
            if (searchInput?.length > 2) {
              navigateTo({
                pathname: '/search',
                search: `?${createSearchParams({ s: searchInput })}`,
              });
            }
          }}
        >
          <Form.Item name="searchInput" rules={[{ required: true, message: 'Enter something to search for' }]}>
            <Input />
          </Form.Item>
          <Button name="search-button" htmlType="submit">
            <MdSearch />
          </Button>
        </Form>
      </div>
    );
  }

  function ResultsList() {
    return (
      <List
        size="large"
        itemLayout="horizontal"
        dataSource={matchingItems.slice((page - 1) * 10, (page - 1) * 10 + 10)}
        renderItem={(item) => (
          <List.Item
            onClick={() => {
              if (item.type === 'product') {
                const category = productCategories.find((c) => c.name === item.category);
                navigateTo(
                  `/products/${urlFormat(category?.shortName || category?.name)}/${urlFormat(
                    item.subCategory
                  )}/${urlFormat(item.name)}`
                );
              } else {
                navigateTo(`/blog/${urlFormat(item.title)}`);
              }
            }}
          >
            <List.Item.Meta
              avatar={
                <Avatar
                  size="large"
                  shape="square"
                  src={
                    item.type === 'product'
                      ? `${STORAGE_URL}thumbs128/${item.images[0]}`
                      : item.image
                      ? `${STORAGE_URL}${item.image}`
                      : `${STORAGE_URL}${LOGO_URL}`
                  }
                />
              }
              title={item.type === 'product' ? `Product: ${item.brand} ${item.name}` : `News: ${item.title}`}
              description={
                item.type === 'product'
                  ? `${item.category} - ${item.subCategory}`
                  : dayjs(item.time).format('MMM D, YYYY')
              }
            />
          </List.Item>
        )}
      />
    );
  }

  function PageControls() {
    return (
      <div className="pagination">
        <Pagination
          showSizeChanger={false}
          current={page}
          pageSize={10}
          total={matchingItems.length}
          onChange={(page) => {
            setPage(page);
            window.scrollTo({ left: 0, top: 0 });
          }}
        />
      </div>
    );
  }

  return (
    <div className="search">
      <h1 className="page-title">Search</h1>
      <div className="content">
        {SearchForm()}
        {matchingItems?.length ? (
          <>
            {ResultsList()}
            {matchingItems.length > 10 && PageControls()}
          </>
        ) : (
          searchParams?.get('s') && <div className="no-results">Your search did not return any results</div>
        )}
      </div>
    </div>
  );
}

export default Search;
