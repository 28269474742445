import { useState, useEffect, useCallback } from 'react';
import { AiOutlineDelete, AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import { collection, getDocs, updateDoc, doc, query } from 'firebase/firestore';
import { Collapse, Form, Input, Switch, Button, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useAtom } from 'jotai';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';

import { productCategoriesAtom } from '../atoms';
import AddProduct from './AddProduct';

import './EditPrices.css';

function ProductPrices({ db, product, setProducts }) {
  let initialValues = {
    price: product.price,
    onSale: product.onSale,
    sizes: product.sizeNames ? product.sizeNames.map((name, i) => ({ name, price: product.sizePrices[i] })) : [],
  };
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState(initialValues);
  const isFormDirty = !isEqual(formValues, initialValues);

  const onFinish = async (submitted) => {
    try {
      const p = {
        price: submitted.price?.trim() || '-',
        onSale: submitted.onSale || false,
        sizeNames: submitted.sizes?.map((s) => s.name?.trim() || '') || [],
        sizePrices: submitted.sizes?.map((s) => s.price?.trim() || '-') || [],
      };
      await updateDoc(doc(db, 'products', product.id), p);
      setProducts();
    } catch (e) {}
  };

  return (
    <div className="product-prices">
      <Form
        form={form}
        autoComplete="off"
        onFinish={onFinish}
        initialValues={initialValues}
        onValuesChange={(_, values) => setFormValues(values)}
      >
        <div className="form-row">
          <Form.Item name="price" label="List price" className="price-input">
            <Input />
          </Form.Item>
          <Form.Item name="onSale" valuePropName="checked">
            <Switch checkedChildren="On sale" unCheckedChildren="On sale" />
          </Form.Item>
        </div>
        <Form.List name="sizes">
          {(fields, { add, move, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, i) => (
                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                  <Form.Item
                    {...restField}
                    name={[name, 'name']}
                    rules={[{ required: true, message: 'Missing name' }]}
                    className="option-name-input"
                  >
                    <Input placeholder="Size/option name" />
                  </Form.Item>
                  <Form.Item {...restField} name={[name, 'price']} className="option-price-input">
                    <Input placeholder="Price" />
                  </Form.Item>
                  <Button
                    className="option-button"
                    onClick={() => move(i, i - 1)}
                    icon={<AiOutlineUp />}
                    disabled={i === 0}
                  />
                  <Button
                    className="option-button"
                    icon={<AiOutlineDown />}
                    onClick={() => move(i, i + 1)}
                    disabled={i === fields.length - 1}
                  />
                  <Button className="option-button" onClick={() => remove(name)} icon={<AiOutlineDelete />} />
                </Space>
              ))}
              <Form.Item className="add-option-button">
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add size/option
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <div className="form-row">
          <Button size="large" htmlType="submit" danger={isFormDirty} disabled={!isFormDirty}>
            Save
          </Button>
          <Button
            size="large"
            disabled={!isFormDirty}
            onClick={() => {
              form.resetFields();
              setFormValues(initialValues);
            }}
          >
            Reset
          </Button>
        </div>
      </Form>
    </div>
  );
}

function EditPrices({ db, storage }) {
  const [productCategories] = useAtom(productCategoriesAtom);
  const [products, setProducts] = useState();
  const [selectedProduct, setSelectedProduct] = useState();

  const fetchProducts = useCallback(() => {
    const ref = collection(db, 'products');
    const q = query(ref);
    getDocs(q).then((querySnapshot) => {
      const list = [];
      querySnapshot.forEach((doc) => list.push({ ...doc.data(), id: doc.id }));
      setProducts(sortBy(list, (p) => p.name));
    });
  }, [db, setProducts]);

  useEffect(() => {
    if (productCategories && !products) fetchProducts();
  }, [productCategories, products, fetchProducts]);

  function ProductSelector() {
    return (
      <Collapse bordered={false}>
        {productCategories?.map((c) => (
          <Collapse.Panel header={c.name} key={c.name}>
            <Collapse bordered={false}>
              {c.subCategories.map((s) => (
                <Collapse.Panel header={s} key={s}>
                  <Collapse bordered={false}>
                    {(products?.filter((p) => p.subCategory === s && !p.hidden) || []).map((product) => (
                      <Collapse.Panel
                        key={product.id}
                        header={`${product.brand} ${product.name} -- ${product.onSale ? 'SALE: ' : ''}${
                          !product.price || product.price === '-' ? 'No price' : product.price
                        }`}
                      >
                        <ProductPrices product={product} setProducts={setProducts} db={db} />
                      </Collapse.Panel>
                    ))}
                  </Collapse>
                </Collapse.Panel>
              ))}
            </Collapse>
          </Collapse.Panel>
        ))}
      </Collapse>
    );
  }

  return (
    <div className="edit-prices">
      {selectedProduct && (
        <AddProduct
          db={db}
          storage={storage}
          existingProduct={selectedProduct}
          backCallback={() => setSelectedProduct()}
          successCallback={() => setProducts()}
        />
      )}
      <div className={selectedProduct ? 'invisible' : 'product-selector'}>{ProductSelector()}</div>
    </div>
  );
}

export default EditPrices;
