import { atom } from 'jotai';

export const userAtom = atom();

export const isMenuStickingAtom = atom(false);

export const productCategoriesAtom = atom();
export const carouselItemsAtom = atom();
export const linksAtom = atom();
export const recentNewsAtom = atom();
export const countsAtom = atom();
export const configAtom = atom();
export const contactInfoAtom = atom();
export const socialsAtom = atom();

export const searchDataAtom = atom();
