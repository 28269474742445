import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { Button, Carousel } from 'antd';
import { getAnalytics, logEvent } from 'firebase/analytics';

import { configAtom } from '../atoms';
import { STORAGE_URL } from '../constants';

import './HGLessons.css';

function CarouselItem(item) {
  return (
    <div className="carousel-item" key={item.image}>
      <div
        className="carousel-img"
        style={{
          backgroundImage: `url(${STORAGE_URL}${item.image})`,
        }}
      ></div>
    </div>
  );
}

const carouselItems = [
  { image: 'hg/1.jpg' },
  { image: 'hg/2.jpg' },
  { image: 'hg/3.jpg' },
  { image: 'hg/4.jpg' },
  { image: 'hg/5.jpg' },
  { image: 'hg/6.jpg' },
];

function HGLessons() {
  const analytics = getAnalytics();
  const [config] = useAtom(configAtom);

  useEffect(() => {
    logEvent(analytics, 'screen_view', { firebase_screen: 'HG Lessons', firebase_screen_class: 'hg-lessons' });
    logEvent(analytics, 'view_hg_lessons');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="hg-lessons">
      <h1 className="page-title">Hang Gliding Lessons</h1>
      <div className="content">
        <div className="row">
          <div className="col">
            <Carousel dotPosition="bottom" autoplay>
              {carouselItems?.map((item) => CarouselItem(item))}
            </Carousel>
          </div>
          <div className="col">
            <h1>INTRO HANG GLIDING</h1>
            <p>
              Muller Windsports Ltd is Western Canada's Largest Free Flying School and has been in operation since 1971.
              Our focus has always been creating knowledgable and safe pilots that will enjoy the sports of Paragliding
              and Hang Gliding for many years to come.
            </p>
            <p>
              Our Introductory courses include a Friday evening ground school at Muller Windsports Shop and Saturday
              Mornings outside in the City of Calgary to get time on the gliders. We use the Sunday Morning as a Bonus
              or Weather day if needed.
            </p>
            <p>
              Programming is through the{' '}
              <a
                href="https://www.ucalgary.ca/ActiveLiving/registration/Browse/All?filters=Outdoor-Centre"
                target="_blank"
                rel="noreferrer"
              >
                University of Calgary Outdoor Program Centre
              </a>
              .
            </p>
            <p>
              Hang Gliding is the simplest form of flying—it's almost like having wings on your back. Lessons with our
              experienced instructors consist of an evening lecture to cover flight theory and logistics, and 4 hours of
              practical flight training in a city park. You'll start with short, low flights and as your skill and
              comfort level increases, you’ll gradually progress up the hill to higher take-off points and longer
              flights. It's safe and fun.
            </p>
            <div className="dates-section">
              <h4>
                <b>2024 Dates for Novice Hang Gliding</b>
              </h4>
              <div className="dates">April 19, 20, and 21</div>
              <div className="dates">May 3, 4, and 5</div>
              <div className="dates">May 31, June 1 and 2</div>
              <div className="dates">August 23, 24, and 25</div>
              <div className="dates">September 20, 21 and 22</div>
              <div className="dates">October 18, 19, and 20</div>
              {config?.showIntroHGLink ? (
                <a className="register-link" href={config?.introHGLink} target="_blank" rel="noreferrer">
                  <Button>Register for Novice Hang Gliding</Button>
                </a>
              ) : (
                <h3 className="no-register-link">Please check back at the start of the next season to register.</h3>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h1>MORE AIRTIME HANG GLIDING COURSE</h1>
            <p>
              Our More Airtime Hang Gliding Course picks up where the Into course left off. We will begin with a review
              of some of the basics covered in the Intro course and then progress your flying skills, increase your
              pilot knowledge and of course up your Airtime. Take the next step toward increasing your hang gliding
              skills and beginning to fly on your own. We will start on similar city hills as the intro course and as
              your skills dictate, progress to launching from the tops of those hills and moving to higher hills which
              will allow for More Airtime.
            </p>
            <p>
              The course consists of 2 evening classroom sessions and 4 weekend morning practical flight training days.{' '}
            </p>
          </div>
          <div className="col">
            <img className="static" alt="" src={`${STORAGE_URL}hg/0.webp`} />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p>
              <b>Skills covered in this course:</b>
            </p>
            <ul>
              <li>Setup/breakdown procedures and pre-flight check</li>
              <li>Perfecting wings level takeoffs and target landings</li>
              <li>Correcting turns to maintain a heading</li>
              <li>Appropriate airspeed and flying speeds for various maneuvers </li>
              <li>Progressing to using a prone harness</li>
            </ul>
            <p>
              <b>Knowledge topics covered in this course:</b>
            </p>
            <ul>
              <li>Aerodynamics of hang gliding - air speed, ground speed, wind speed, glide ratio, flying speeds, </li>
              <li>Meteorology - weather as it applies to flying a hang glider</li>
              <li>Transport Canada aviation regulations</li>
            </ul>
          </div>
          <div className="col">
            <p>
              <b>The course includes:</b>
            </p>
            <ul>
              <li>Use of all flying equipment - hang glider, harness, helmet</li>
              <li>Hang gliding aerodynamics and flying skills textbook</li>
              <li>Logbook - to log all your flights </li>
              <li>HPAC H1 Student written exam</li>
            </ul>
          </div>
        </div>
        <p className="row">
          <center>
            <a className="register-link" href="/hg-lessons/deposit">
              <Button>Pay your deposit for Intermediate Hang Gliding</Button>
            </a>
          </center>
        </p>
        <div className="row">
          <div className="col">
            <img className="static" alt="" src={`${STORAGE_URL}hg/hggolden.jpg`} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HGLessons;
