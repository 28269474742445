import { useState, useEffect, useCallback } from 'react';
import { collection, getDocs, query } from 'firebase/firestore';
import { Collapse, Avatar, List } from 'antd';
import sortBy from 'lodash/sortBy';
import dayjs from 'dayjs';

import { LOGO_CUT_URL, STORAGE_URL } from '../constants';
import AddBlog from './AddBlog';

import './EditBlog.css';

function EditBlog({ db, storage }) {
  const [blogs, setBlogs] = useState();
  const [selectedBlog, setSelectedBlog] = useState();

  const fetchBlogs = useCallback(() => {
    const ref = collection(db, 'blog');
    const q = query(ref);
    getDocs(q).then((querySnapshot) => {
      const list = [];
      querySnapshot.forEach((doc) => list.push({ ...doc.data(), id: doc.id }));
      const listByMonth = [];
      sortBy(list, (p) => p.time)
        .reverse()
        .forEach((b) => {
          const month = dayjs(b.time).format('MMMM YYYY');
          const existing = listByMonth.find((i) => i.month === month);
          if (existing) {
            existing.blogs.push(b);
          } else {
            listByMonth.push({ month, blogs: [b] });
          }
        });
      setBlogs(listByMonth);
    });
  }, [db, setBlogs]);

  useEffect(() => {
    if (!blogs) fetchBlogs();
  }, [blogs, fetchBlogs]);

  function BlogSelector() {
    return (
      <Collapse bordered={false}>
        {blogs?.map((section) => (
          <Collapse.Panel header={`${section.month} (${section.blogs.length})`} key={section.month}>
            <List
              itemLayout="horizontal"
              dataSource={section.blogs || []}
              renderItem={(item) => (
                <List.Item onClick={() => setSelectedBlog(item)}>
                  <List.Item.Meta
                    avatar={<Avatar shape="square" size="large" src={`${STORAGE_URL}${item.image || LOGO_CUT_URL}`} />}
                    title={item.title}
                    description={dayjs(item.time).format('MMM D, YYYY')}
                  />
                </List.Item>
              )}
            />
          </Collapse.Panel>
        ))}
      </Collapse>
    );
  }

  return (
    <div className="edit-blog">
      {selectedBlog ? (
        <AddBlog
          db={db}
          storage={storage}
          existingBlog={selectedBlog}
          backCallback={() => setSelectedBlog()}
          successCallback={() => setBlogs()}
        />
      ) : (
        BlogSelector()
      )}
    </div>
  );
}

export default EditBlog;
