import dayjs from 'dayjs';

import { STORAGE_URL } from '../constants';

import './BlogDisplay.css';

function BlogDisplay({ blog }) {
  return (
    <div className="blog-display">
      <div className="date">{dayjs(blog.time).format('MMM D, YYYY')}</div>
      {blog.image && <img className="image" alt="" src={`${STORAGE_URL}${blog.image}`} />}
      <div className="text ql-editor" dangerouslySetInnerHTML={{ __html: blog.text || 'Example blog text' }} />
    </div>
  );
}

export default BlogDisplay;
