import { useEffect } from 'react';
import { Button, Carousel, Grid } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { useAtom } from 'jotai';
import dayjs from 'dayjs';

import { urlFormat } from './util';
import { STORAGE_URL } from './constants';
import { productCategoriesAtom, carouselItemsAtom, recentNewsAtom } from './atoms';

import './Home.css';

function CarouselItem(item) {
  return (
    <div className="carousel-item" key={item.image}>
      <div
        className="carousel-img"
        style={{
          backgroundImage: `url(${STORAGE_URL}${item.image})`,
        }}
      ></div>
    </div>
  );
}

function Home() {
  const navigate = useNavigate();
  const analytics = getAnalytics();
  const screen = Grid.useBreakpoint();

  const [productCategories] = useAtom(productCategoriesAtom);
  const [carouselItems] = useAtom(carouselItemsAtom);
  const [recentNews] = useAtom(recentNewsAtom);

  useEffect(() => {
    logEvent(analytics, 'screen_view', { firebase_screen: 'Home', firebase_screen_class: 'home' });
    logEvent(analytics, 'view_home');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigateTo = (url) => {
    navigate(url);
    window.scrollTo({ left: 0, top: 0 });
  };

  const items = carouselItems.filter((c) => (screen.lg && c.landscape) || (!screen.lg && !c.landscape));

  function ProductButton(product) {
    const url = `/products/${urlFormat(product.shortName || product.name)}`;
    return (
      <Button className="product-cat-button" key={product.index} onClick={() => navigateTo(url)}>
        <div className="product-cat-button-content">
          <div className="product-cat-button-header">
            <h1>{product.shortName || product.name}</h1>
          </div>
          <div className="product-cat-button-img">
            <img alt={product.shortName || product.name} src={`${STORAGE_URL}${product.image}`} />
          </div>
        </div>
      </Button>
    );
  }

  function BlogButton(blog) {
    const url = `/blog/${blog.url}`;
    return (
      <Button className="blog-button" key={blog.url} onClick={() => navigateTo(url)}>
        <div className="date">{dayjs(blog.time).format('MMM D, YYYY')}</div>
        <h6>{blog.title}</h6>
        <p className="read-more">Read more</p>
      </Button>
    );
  }

  return (
    <div className="home">
      <Carousel dotPosition="bottom" autoplay>
        {items?.map((item) => CarouselItem(item))}
      </Carousel>
      <div className="content">
        <div className="content-row products-row">{productCategories?.map((p) => ProductButton(p))}</div>
        <div className="content-row mt">
          <div className="content-col">
            <div className="content-subrow">
              <Button className="blog-main-button" onClick={() => navigateTo('/blog')}>
                <h1>News</h1>
                <p className="read-all">Read all</p>
              </Button>
            </div>
            <div className="content-subrow">{recentNews?.map((n) => BlogButton(n))}</div>
          </div>
        </div>
        <div className="bottom-spacer" />
      </div>
    </div>
  );
}

export default Home;
