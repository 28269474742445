import { useEffect, useState, useCallback } from 'react';
import { collection, getDocs, query, orderBy, limit, startAfter } from 'firebase/firestore';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { Button, Pagination, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import dayjs from 'dayjs';

import { STORAGE_URL } from '../constants';
import { countsAtom, userAtom } from '../atoms';

import './Blog.css';

function Blog({ db }) {
  const navigate = useNavigate();
  const analytics = getAnalytics();

  const [user] = useAtom(userAtom);
  const [counts] = useAtom(countsAtom);
  const [blogs, setBlogs] = useState();
  const [page, setPage] = useState(1);
  const [lastVisible, setLastVisible] = useState();

  const fetchBlogs = useCallback(() => {
    if (!blogs?.length) {
      const ref = collection(db, 'blog');
      const q = query(ref, orderBy('time', 'desc'), limit(5));
      getDocs(q)
        .then((querySnapshot) => {
          const list = [];
          querySnapshot.forEach((doc) => list.push({ ...doc.data(), id: doc.id }));
          setBlogs(list);
          setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        })
        .catch(() => logEvent(analytics, 'db_fetch_error', { location: 'blog' }));
    } else if (page > 1 && blogs.length === 5 && lastVisible) {
      const nextQ = query(collection(db, 'blog'), orderBy('time', 'desc'), startAfter(lastVisible));
      getDocs(nextQ)
        .then((querySnapshot) => {
          const list = [];
          querySnapshot.forEach((doc) => list.push({ ...doc.data(), id: doc.id }));
          setBlogs((oldBlogs) => [...oldBlogs, ...list]);
          setLastVisible();
        })
        .catch(() => logEvent(analytics, 'db_fetch_error', { location: 'blog' }));
    }
  }, [db, setBlogs, blogs, page, lastVisible, analytics]);

  useEffect(() => {
    if (user?.uid) fetchBlogs();
  }, [user, fetchBlogs]);

  useEffect(() => {
    if (analytics) {
      logEvent(analytics, 'screen_view', { firebase_screen: 'Blog', firebase_screen_class: 'blog' });
      logEvent(analytics, 'view_blog');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analytics]);

  useEffect(() => {
    if (analytics && blogs?.length) {
      logEvent(analytics, 'loaded_blogs', { count: blogs.length });
    }
  }, [analytics, blogs]);

  const navigateTo = (url) => {
    navigate(url);
    window.scrollTo({ left: 0, top: 0 });
  };

  function PageControls() {
    return (
      <div className="pagination">
        <Pagination
          showSizeChanger={false}
          current={page}
          pageSize={5}
          total={counts?.blogs}
          onChange={(page) => {
            setPage(page);
            window.scrollTo({ left: 0, top: 0 });
          }}
        />
      </div>
    );
  }

  function BlogButton(blog) {
    return (
      <div className="blog-button" key={blog.url}>
        <div className="heading">
          <div className="date">{dayjs(blog.time).format('MMM D, YYYY')}</div>
          <h1 className="title">{blog.title}</h1>
        </div>
        {blog.image && <img className="image" alt="" src={`${STORAGE_URL}${blog.image}`} />}
        <div className="text" dangerouslySetInnerHTML={{ __html: blog.text }} />
        <Button className="read-link" type="link" onClick={() => navigateTo(`/blog/${blog.url}`)}>
          Read more
        </Button>
      </div>
    );
  }

  const blogsToShow = blogs?.length ? blogs.slice((page - 1) * 5, (page - 1) * 5 + 5) : [];

  return (
    <div className="blog">
      <h1 className="page-title">News</h1>
      <div className="content">
        {blogsToShow?.length ? (
          blogsToShow.map((blog) => BlogButton(blog))
        ) : (
          <div className="loading load-page">
            <Spin size="large" />
          </div>
        )}
        {PageControls()}
      </div>
    </div>
  );
}

export default Blog;
