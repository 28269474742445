import { Layout } from 'antd';
import { FaFacebookF, FaInstagram, FaVimeoV, FaYoutube, FaTwitterSquare, FaTiktok } from 'react-icons/fa';
import { useAtom } from 'jotai';
import dayjs from 'dayjs';

import { linksAtom, contactInfoAtom, socialsAtom, configAtom } from './atoms';

import './Footer.css';

function Footer({ isLoaded }) {
  const [links] = useAtom(linksAtom);
  const [contactInfo] = useAtom(contactInfoAtom);
  const [socials] = useAtom(socialsAtom);
  const [config] = useAtom(configAtom);
  return (
    isLoaded && (
      <Layout.Footer>
        <div className="footer-rows">
          <div className="footer-row links-bar">
            <div className="links-cols">
              <div className="links-col">
                <h4>MULLER LINKS</h4>
                {links.map((link) => (
                  <a key={link.label} href={link.link} target="_blank" rel="noreferrer">
                    {link.label}
                  </a>
                ))}
              </div>
              <div className="links-col">
                <h4>CONTACT US</h4>
                <p>
                  Email: <a href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a>
                </p>
                <p>Phone: {contactInfo.phone}</p>
                <p>Mail: {contactInfo.mail}</p>
                <a href={contactInfo.mapLink} target="_blank" rel="noreferrer">
                  Map to Our Location
                </a>
              </div>
              {(config?.showWaiver || config?.showDeposit) && (
                <div className="links-col-outer">
                  {config.showWaiver && (
                    <div className="links-col">
                      <h4>WAIVER & COURSE DEPOSIT</h4>
                      <a href={config?.waiverLink} target="_blank" rel="noreferrer" className="waiver-button">
                        Sign {dayjs().year()} Digital Waiver
                      </a>
                    </div>
                  )}
                  {config.showDeposit && (
                    <div className="links-col">
                      {!config.showWaiver && <h4>COURSE DEPOSIT</h4>}
                      <a href="/deposit" className="waiver-button">
                        Pay Intermediate Course Deposit
                      </a>
                    </div>
                  )}
                </div>
              )}
              <div className="links-col">
                <h4>FOLLOW US</h4>
                <div className="socials">
                  {socials.facebook && (
                    <a href={socials.facebook} target="_blank" rel="noreferrer">
                      <FaFacebookF />
                    </a>
                  )}
                  {socials.instagram && (
                    <a href={socials.instagram} target="_blank" rel="noreferrer">
                      <FaInstagram />
                    </a>
                  )}
                  {socials.twitter && (
                    <a href={socials.twitter} target="_blank" rel="noreferrer">
                      <FaTwitterSquare />
                    </a>
                  )}
                  {socials.youtube && (
                    <a href={socials.youtube} target="_blank" rel="noreferrer">
                      <FaYoutube />
                    </a>
                  )}
                  {socials.vimeo && (
                    <a href={socials.vimeo} target="_blank" rel="noreferrer">
                      <FaVimeoV />
                    </a>
                  )}
                  {socials.tiktok && (
                    <a href={socials.tiktok} target="_blank" rel="noreferrer">
                      <FaTiktok />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="footer-row bottom-bar">
            <div className="copyright">© {dayjs().year()}, Muller Windsports LTD.</div>
          </div>
        </div>
      </Layout.Footer>
    )
  );
}

export default Footer;
