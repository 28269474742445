import { useEffect, useState, useCallback } from 'react';
import { collection, getDocs, query, limit, where } from 'firebase/firestore';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { useParams } from 'react-router-dom';

import BlogDisplay from './BlogDisplay';

import './BlogItem.css';

function BlogItem({ db }) {
  const params = useParams();
  const analytics = getAnalytics();

  const [blog, setBlog] = useState();

  const fetchBlog = useCallback(() => {
    const ref = collection(db, 'blog');
    const q = query(ref, where('url', '==', params.id), limit(1));
    getDocs(q).then((querySnapshot) => {
      const list = [];
      querySnapshot.forEach((doc) => list.push({ ...doc.data(), id: doc.id }));
      if (list.length) setBlog(list[0]);
    });
  }, [db, setBlog, params.id]);

  useEffect(() => {
    fetchBlog();
  }, [fetchBlog]);

  useEffect(() => {
    if (analytics && blog?.title) logEvent(analytics, 'view_blog', { title: blog.title });
  }, [analytics, blog?.title]);

  useEffect(() => {
    logEvent(analytics, 'screen_view', { firebase_screen: 'Blog Item', firebase_screen_class: 'blog-item' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="blog-item">
      <div className="page-title">{blog?.title}</div>
      <div className="content">{blog && <BlogDisplay blog={blog} />}</div>
    </div>
  );
}

export default BlogItem;
