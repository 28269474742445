import { useEffect, useState, useCallback } from 'react';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { Button, Breadcrumb, Select, Pagination, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import { useAtom } from 'jotai';

import { urlFormat } from '../util';
import { productCategoriesAtom, userAtom } from '../atoms';
import ProductDisplay from './ProductDisplay';
import ProductButton from './ProductButton';

import './Products.css';

const sortOptions = [
  { label: 'Name, A-Z', sorter: (p) => p.name, reverse: false },
  { label: 'Name, Z-A', sorter: (p) => p.name, reverse: true },
  {
    label: 'Price, low to high',
    sorter: (p) => Number(p.price.replace(/[^0-9.]/g, '')),
    reverse: false,
  },
  {
    label: 'Price, high to low',
    sorter: (p) => Number(p.price.replace(/[^0-9.]/g, '')),
    reverse: true,
  },
];

const filterAll = { label: 'All', filter: () => true };

function Products({ db, onlySaleItems }) {
  const params = useParams();
  const navigate = useNavigate();
  const analytics = getAnalytics();

  const [user] = useAtom(userAtom);
  const [products, setProducts] = useState();

  const brandOptions = products?.length
    ? uniqBy(
        products.map((p) => ({
          label: p.brand,
          filter: (p2) => p2.brand === p.brand,
        })),
        'label'
      )
    : [];

  const [filterOption, setFilterOption] = useState(filterAll);
  const [sortOption, setSortOption] = useState(sortOptions[0]);
  const [page, setPage] = useState(1);
  const [productCategories] = useAtom(productCategoriesAtom);

  const navigateTo = (url) => {
    setPage(1);
    navigate(url);
    window.scrollTo({ left: 0, top: 0 });
  };

  const sortProducts = (list) => {
    const sorted = sortBy(list, sortOption.sorter);
    return sortOption.reverse ? sorted.reverse() : sorted;
  };

  const filterProducts = (list) => {
    const filtered = list?.filter(filterOption?.filter || (() => true));
    return filtered;
  };

  const inputCat = params.category && params.category === 'kiting' ? 'ozone-kites' : params.category;
  const category = inputCat && productCategories?.find((c) => urlFormat(c.shortName || c.name) === inputCat);
  const subCategory = category?.subCategories.find((s) => urlFormat(s) === params.subCategory);
  const product = params.product && products?.find((p) => urlFormat(p.name) === params.product);

  const fetchProducts = useCallback(() => {
    const ref = collection(db, 'products');
    let q;
    if (onlySaleItems) q = query(ref, where('onSale', '==', true), where('hidden', '!=', true));
    else if (subCategory) q = query(ref, where('subCategory', '==', subCategory), where('hidden', '!=', true));
    else if (category) q = query(ref, where('category', '==', category.name), where('hidden', '!=', true));
    else q = query(ref, where('hidden', '!=', true));
    getDocs(q)
      .then((querySnapshot) => {
        const list = [];
        querySnapshot.forEach((doc) => list.push({ ...doc.data(), id: doc.id }));
        if (list.length) setProducts(list);
      })
      .catch(() => logEvent(analytics, 'db_fetch_error', { location: 'products' }));
  }, [category, subCategory, onlySaleItems, db, setProducts, analytics]);

  useEffect(() => {
    if (user?.uid && productCategories) fetchProducts();
  }, [user, productCategories, fetchProducts]);

  useEffect(() => {
    logEvent(analytics, 'screen_view', {
      firebase_screen: 'Products',
      firebase_screen_class: 'products',
    });
    logEvent(analytics, 'view_products');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (analytics && products?.length) {
      logEvent(analytics, 'loaded_products', { count: products.length });
    }
  }, [analytics, products]);

  const onChangeSort = (value) => setSortOption(sortOptions.find((o) => o.label === value));

  const onChangeFilter = (value) => {
    let option = brandOptions.find((o) => o.label === value);
    setFilterOption(option || filterAll);
  };

  function BreadCrumb() {
    return (
      <div className="breadcrumb">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Button type="link" onClick={() => navigateTo('/products')}>
              Products
            </Button>
          </Breadcrumb.Item>
          {onlySaleItems && (
            <Breadcrumb.Item>
              <Button type="link" onClick={() => navigateTo('/products/sale')}>
                On Sale
              </Button>
            </Breadcrumb.Item>
          )}
          {category && (
            <Breadcrumb.Item>
              <Button
                type="link"
                onClick={() => navigateTo(`/products/${urlFormat(category?.shortName || category?.name)}`)}
              >
                {category?.name}
              </Button>
            </Breadcrumb.Item>
          )}
          {subCategory && (
            <Breadcrumb.Item>
              <Button
                type="link"
                onClick={() =>
                  navigateTo(`/products/${urlFormat(category?.shortName || category?.name)}/${urlFormat(subCategory)}`)
                }
              >
                {subCategory}
              </Button>
            </Breadcrumb.Item>
          )}
          {product && (
            <Breadcrumb.Item>
              <Button
                type="link"
                onClick={() =>
                  navigateTo(
                    `/products/${urlFormat(category?.shortName || category?.name)}/${urlFormat(
                      subCategory
                    )}/${urlFormat(product?.name)}`
                  )
                }
              >
                {product.name}
              </Button>
            </Breadcrumb.Item>
          )}
        </Breadcrumb>
      </div>
    );
  }

  function ProductListControls() {
    return (
      <div className="product-list-controls">
        {(category || onlySaleItems) && BreadCrumb()}
        <div className="product-control">
          <div className="product-control-label">Filter by</div>
          <Select style={{ minWidth: 140 }} value={filterOption.label} onChange={onChangeFilter}>
            <Select.Option key="all" value={filterAll.label}>
              {filterAll.label}
            </Select.Option>
            {brandOptions.length && (
              <Select.OptGroup label="Brand">
                {brandOptions.map((o) => (
                  <Select.Option key={o.label} value={o.label}>
                    {o.label}
                  </Select.Option>
                ))}
              </Select.OptGroup>
            )}
          </Select>
        </div>
        <div className="product-control">
          <div className="product-control-label">Sort by</div>
          <Select style={{ minWidth: 140 }} value={sortOption.label} onChange={onChangeSort}>
            {sortOptions.map((o) => (
              <Select.Option key={o.label} value={o.label}>
                {o.label}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
    );
  }

  function PageControls() {
    return (
      <div className="pagination">
        <Pagination
          showSizeChanger={false}
          current={page}
          onChange={(page) => {
            setPage(page);
            window.scrollTo({ left: 0, top: 0 });
          }}
          pageSize={20}
          total={products.length}
        />
      </div>
    );
  }

  function ProductList() {
    const productsToShow = sortProducts(filterProducts(products))?.slice((page - 1) * 20, (page - 1) * 20 + 20);
    return (
      <>
        {ProductListControls()}
        <div className="product-list">
          {productsToShow?.length ? (
            productsToShow.map((p) => <ProductButton key={p.name} product={p} hideSale={!!onlySaleItems} />)
          ) : (
            <div className="loading load-page">
              <Spin size="large" />
            </div>
          )}
        </div>
        {products?.length && filterProducts(products).length > 20 && PageControls()}
      </>
    );
  }

  return (
    <div className="products">
      {!product && (
        <h1 className="page-title">{onlySaleItems ? 'On Sale' : subCategory || category?.name || 'All Products'}</h1>
      )}
      <div className="content">
        {product ? (
          <ProductDisplay key={product.id} product={product} otherProducts={products} breadcrumb={BreadCrumb()} />
        ) : (
          ProductList()
        )}
      </div>
    </div>
  );
}

export default Products;
