import { useState, useEffect } from 'react';
import shuffle from 'lodash/shuffle';
import ImageGallery from 'react-image-gallery';
import { Select, Menu, Grid, Table } from 'antd';
import { getAnalytics, logEvent } from 'firebase/analytics';

import { STORAGE_URL } from '../constants';
import ProductButton from './ProductButton';

import './ProductDisplay.css';

function ProductDisplay({ product, breadcrumb, otherProducts }) {
  const screen = Grid.useBreakpoint();
  const analytics = getAnalytics();

  useEffect(() => {
    if (analytics && product?.name) logEvent(analytics, 'view_product', { name: product.name });
  }, [analytics, product.name]);

  useEffect(() => {
    logEvent(analytics, 'screen_view', { firebase_screen: 'Product', firebase_screen_class: 'product' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedTab, setSelectedTab] = useState('overview');
  const [selectedSize, setSelectedSize] = useState(-1);
  const selectedSizePrice = selectedSize > -1 && product.sizePrices && product.sizePrices[selectedSize];

  const menuOptions = [{ key: 'overview', label: 'Overview' }];

  if (product.photos?.length) menuOptions.push({ key: 'photos', label: 'Photos' });
  if (product.videoLink) menuOptions.push({ key: 'videos', label: 'Videos' });
  if (product.features) menuOptions.push({ key: 'features', label: 'Features' });
  if (product.specifications?.length && product.specificationHeaders)
    menuOptions.push({ key: 'specifications', label: 'Specifications' });

  function ProductImages() {
    const items = product.images.map((img) => ({
      key: img,
      fullscreen: `${STORAGE_URL}thumbs1024/${img}`,
      original: `${STORAGE_URL}thumbs1024/${img}`,
      thumbnail: `${STORAGE_URL}thumbs128/${img}`,
    }));
    return (
      <div className="product-gallery">
        <ImageGallery
          showFullscreenButton={false}
          showPlayButton={false}
          showThumbnails={product.images.length > 1}
          items={items}
          lazyLoad
        />
      </div>
    );
  }

  function getPriceLabel() {
    const price = selectedSizePrice || product.price;
    return price === '-' ? '' : price;
  }

  function ProductMain() {
    return (
      <div className="product-main">
        <div className="top-container">
          <h4 className="brand">{product.brand || 'Example Brand'}</h4>
          <h1 className="name">{product.name || 'Example Product'}</h1>
          {product.onSale && <div className="on-sale-banner">SALE</div>}
        </div>
        <div className="bottom-container">
          <h2 className={product.onSale ? 'price on-sale' : 'price'}>{getPriceLabel()}</h2>
          {!!(product.sizeNames?.length && product.sizePrices?.length) && (
            <Select
              className="size-select"
              placeholder="Available Options"
              style={{ width: 180 }}
              onChange={(value) => setSelectedSize(value)}
            >
              <Select.Option value={-1}>-</Select.Option>
              {product.sizeNames.map((name, i) => (
                <Select.Option key={name} value={i}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          )}
          {product.infoLine && <div className="info-line">{product.infoLine}</div>}
          {product.subTitle && <h2 className="sub-title">{product.subTitle}</h2>}
          {product.moreInfoLink && (
            <a className="more-info-link" href={product.moreInfoLink} target="_blank" rel="noreferrer">
              Click here for more information from the manufacturer
            </a>
          )}
          <div className="please-contact">Please contact us by phone or email to purchase</div>
        </div>
      </div>
    );
  }

  function ProductTabs() {
    return (
      menuOptions.length > 1 && (
        <div className="product-menu">
          <Menu
            onClick={({ key }) => setSelectedTab(key)}
            selectedKeys={[selectedTab]}
            items={menuOptions}
            mode={screen.lg ? 'horizontal' : 'vertical'}
          />
        </div>
      )
    );
  }

  function Overview() {
    return (
      <div
        className="desc"
        dangerouslySetInnerHTML={{ __html: product.description || 'Example product description' }}
      />
    );
  }

  function Features() {
    return (
      <div className="desc" dangerouslySetInnerHTML={{ __html: product.features || 'Example product features' }} />
    );
  }

  function Specifications() {
    const specifications = product.specifications?.map((row, i) => {
      const values = row.split(';');
      const result = { key: `${i}` };
      values.forEach((v, j) => (result[`${j}`] = v));
      return result;
    });
    const specificationHeaders =
      specifications?.length &&
      product.specificationHeaders?.split(';').map((h, i) => ({ title: h, dataIndex: `${i}`, key: `${i}` }));
    return (
      specifications &&
      specificationHeaders && (
        <div className="desc specs">
          <Table dataSource={specifications} columns={specificationHeaders} pagination={false} size="small" />
        </div>
      )
    );
  }

  function Photos() {
    const items = product.photos.map((img) => ({
      key: img,
      original: `${STORAGE_URL}thumbs1024/${img}`,
      thumbnail: `${STORAGE_URL}thumbs128/${img}`,
      fullscreen: `${STORAGE_URL}${img}`,
    }));
    return (
      <div className="photos">
        <ImageGallery thumbnailPosition="top" showThumbnails={product.images.length > 1} items={items} lazyLoad />
      </div>
    );
  }

  function Videos() {
    return (
      <div className="product-video">
        <iframe
          src={product.videoLink}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
    );
  }

  function ProductMore() {
    switch (selectedTab) {
      case 'features':
        return Features();
      case 'specifications':
        return Specifications();
      case 'photos':
        return Photos();
      case 'videos':
        return Videos();
      case 'overview':
      default:
        return Overview();
    }
  }

  function OtherProducts() {
    const buttons = shuffle(otherProducts.filter((p) => p.name !== product.name))
      .slice(0, 4)
      .map((p) => <ProductButton key={p.name} product={p} />);
    return (
      <div className="other-products">
        <h1 className="other-heading">You may also like:</h1>
        <div className="product-list">{buttons}</div>
      </div>
    );
  }

  return (
    <div className="product">
      {breadcrumb}
      <div className="product-row">
        {ProductImages()}
        {ProductMain()}
      </div>
      {ProductTabs()}
      <div className="product-more ql-editor">{ProductMore()}</div>
      {!!otherProducts?.filter((p) => p.name !== product.name).length && OtherProducts()}
    </div>
  );
}

export default ProductDisplay;
